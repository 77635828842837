<template>
  <comments-field
    v-if="getRole != 2"
    :messages="comments"
    document="connection"
    :documentId="document._id"
    :partner="document.partner._id"
    @toUpdate="updateComments"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { sendRequest } from '../../../../utils/graphql';
import Comments from '../../../ui/Comments';

export default {
  name: 'DocumentCommentaries',
  props: ['document'],
  data: () => ({
    comments: []
  }),
  components: {
    'comments-field': Comments
  },
  computed: {
    ...mapGetters(['getToken', 'getRole'])
  },
  mounted() {
    this.updateComments();
  },
  methods: {
    updateComments() {
      const token = this.getToken;
      const query = `query CommentFetchByDocument($id: String, $doc: String) {
        commentFetchByDocument(id: $id, doc: $doc) {
          _id
          message
          createdBy { name }
        }
      }`;
      const variables = {
        id: this.document._id.toString(),
        doc: 'connection'
      };

      sendRequest(token, query, variables, result => {
        // Тут загружены все комментарии если они были
        if (result && Array.isArray(result.data.commentFetchByDocument)) {
          this.comments = result.data.commentFetchByDocument;
        }
      });
    }
  }
};
</script>

<style></style>
