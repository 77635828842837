<template>
  <v-card style="margin: 20px">
    <v-tabs fixed-tabs background-color="indigo" dark v-model="tab">
      <v-btn height="100%" to="/connections">
        Назад
      </v-btn>
      <v-tab>
        Заявление
      </v-tab>
      <v-tab>
        Файлы
      </v-tab>
      <v-tab v-if="getRole !== 2">
        Комментарии
      </v-tab>
      <v-tab v-if="getRole >= 5">
        Управление
      </v-tab>
      <v-tab>
        Документы по результатам
      </v-tab>
      <v-tab>
        ПАО Красноярскэнергосбыт
      </v-tab>
      <v-tab v-if="getRole >= 5">
        События
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <document-text :document="document" />
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <document-files :files="document.files" :id="document._id" />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text><document-comments :document="document"/></v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="getRole >= 5">
        <v-card flat>
          <v-card-text><document-ctrls :document="document"/></v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="getRole != 2">
        <v-card flat>
          <v-card-text><document-results :document="document"/></v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text><document-provider :document="document"/></v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="getRole >= 5">
        <v-card flat>
          <v-card-text
            ><events-warning :partnerId="document.partner._id" :partner="true"
          /></v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import DocumentText from './Document';
import DocumentFiles from './Files';
import DocumentComments from './Commentaries';
import DocumentCtrls from './Controll';
import DocumentResults from './Results';
import EventsWarning from '../../../ui/WarnConnectionsEvent';
import { mapGetters, mapMutations } from 'vuex';
import { sendRequest } from '../../../../utils/graphql';
import ProviderFiles from './ProviderFiles';

export default {
  name: 'DetailConnection',
  props: ['id', 'page'],
  data: () => ({
    tab: 0,
    document: {
      partner: {},
      files: []
    }
  }),
  components: {
    'document-text': DocumentText,
    'document-files': DocumentFiles,
    'document-comments': DocumentComments,
    'document-ctrls': DocumentCtrls,
    'document-results': DocumentResults,
    'document-provider': ProviderFiles,
    'events-warning': EventsWarning
  },
  watch: {
    page(value) {
      switch (value) {
        case 'files':
          this.tab = 1;
          break;
        case 'comments':
          this.tab = 2;
          break;
        case 'ctrl':
          this.tab = 3;
          break;
        case 'docs':
          this.tab = 4;
          break;
        case 'provider':
          this.tab = 5;
          break;
        default:
          this.tab = 0;
      }
    }
  },
  computed: {
    ...mapGetters(['getToken', 'getRole'])
  },
  async mounted() {
    await this.fetchConnection();
    switch (this.page) {
      case 'files':
        this.tab = 1;
        break;
      case 'comments':
        this.tab = 2;
        break;
      case 'ctrl':
        this.tab = 3;
        break;
      case 'docs':
        this.tab = 4;
        break;
      case 'provider':
        this.tab = 5;
        break;
      default:
        this.tab = 0;
    }
  },
  methods: {
    ...mapMutations(['setMessageData']),
    async fetchConnection() {
      const token = this.getToken;
      const resData = this.$store.state.univarsal.defaultDataToFetch.connection;
      const query = `
        query ConnectionFetch($id: String!) {
          connectionFetch(id: $id) {
            ${resData}
          }
        }
      `;
      const variables = {
        id: this.id
      };
      const result = await sendRequest(token, query, variables);

      let message = '';
      if (result.errors) {
        const errorText = result.errors[0].data.reduce((summ, curr) => {
          return summ + curr.message + ', ';
        }, '');
        message = `Ошибка: ${errorText}`;
        this.setMessageData({
          message,
          type: 'snackbar'
        });
      } else {
        // Получен результат
        this.document = result.data.connectionFetch;
      }
    }
  }
};
</script>

<style></style>
