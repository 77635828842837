<template>
  <v-card>
    <v-card-title>Загрузка документов</v-card-title>
    <v-card-subtitle v-if="loading">
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </v-card-subtitle>
    <v-card-text>
      <v-select
        v-model="documentType"
        dense
        outlined
        label="Вид документа"
        :items="documentTypes"
      />
      <v-text-field
        v-model="description"
        label="Описание документа"
        dense
        outlined
      />
      <v-file-input
        v-model="files"
        dense
        outlined
        multiple
        accept="image/png,
                image/jpeg,
                image/jpg,
                application/pdf,
                application/msword,
                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                application/vnd.ms-excel,
                application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                application/vnd.visio"
        prepend-icon="mdi-image"
        label="Файл"
        hint="Выберите до 10 файлов"
        persistent-hint
      ></v-file-input>
      <v-btn @click.prevent="uploadHandler" :disabled="loading"
        >Загрузить файлы</v-btn
      >
    </v-card-text>
    <v-overlay :value="overlay" :opacity="0.6">
      <div class="d-flex flex-column align-center text-center">
        <div v-if="loadingImages" style="width: 100%">
          <div>Идет загрузка файлов. Подождите пожалуйста.</div>
          <v-progress-circular
            :rotate="360"
            :size="100"
            :width="15"
            :value="imgLoadProgress"
            color="primary"
          >
            <b>{{ imgLoadProgress }}</b>
          </v-progress-circular>
        </div>
        <div v-else>
          <div>Загрузка. Подождите пожалуйста.</div>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </div>
      </div>
    </v-overlay>
  </v-card>
</template>

<script>
import { mapMutations } from 'vuex';

const { sendRequest } = require('../../utils/graphql');

const PATH = process.env.VUE_APP_UPLOADIMAGE;

export default {
  name: 'ConnectionFilesLoader',
  props: ['doc'],
  data: () => ({
    loading: false,
    loadingImages: false,
    overlay: false,
    imgLoadProgress: 0,
    files: [],
    documentType: 0,
    description: '',
    documentTypes: [
      { text: 'Иной', value: 'Иной' },
      { text: 'Счет на оплату', value: 'Счет на оплату' }
    ]
  }),
  methods: {
    ...mapMutations(['setMessageData']),
    async uploadHandler() {
      if (this.files) {
        const validSize = [];
        const dataBodys = [];
        // Подготовка данных для отправки. Multer ждет не более 10 штук каждого вида переменных ниже
        if (Array.isArray(this.files)) {
          // Если множественная загрузка файлов
          this.files.forEach(f => {
            if (f.size > 2000000) {
              validSize.push(f.name);
            }
            const dataBody = new FormData();
            dataBody.append('files', f);
            dataBody.append('description', this.description);
            dataBody.append('tag', this.documentType);
            dataBody.append('userId', this.$store.state.userId);
            dataBodys.push(dataBody);
          });
        } else {
          // Если одиночная загрузка файлов
          // Проверка на размер файла. Не более 2 мегабайт
          if (this.files.size > 2000000) {
            validSize.push(this.files.name);
          }
          const dataBody = new FormData();
          dataBody.append('files', this.files);
          dataBody.append('description', this.description);
          dataBody.append('tag', this.documentType);
          dataBody.append('userId', this.$store.state.userId);
          dataBodys.push(dataBody);
        }

        if (validSize.length) {
          this.setMessageData({
            message:
              'Следующие файлы превышают размер 2МБ: ' +
              validSize.map(name => name + ' ') +
              ' Пожалуйста сократите размер и повторите попытку.',
            type: 'snackbar'
          });

          this.loadingImages = false;
          this.overlay = false;
          return false;
        }

        const images = []; // Сюда сохраним данные о вновь созданных изображениях в базе данных
        // Делаем запрос к API на принятие новых файлов
        this.loading = true;
        this.overlay = true;
        this.loadingImages = true;
        this.imgLoadProgress = 0;
        try {
          const step = Math.floor(100 / +dataBodys.length);
          for (const dataBody of dataBodys) {
            const result = await fetch(PATH, {
              method: 'POST',
              headers: {
                Authorization: 'Bearer ' + this.$store.state.token
              },
              body: dataBody
            });
            const resData = await result.json();

            if (resData.errors) {
              let errMessage = '';
              resData.errors.forEach(e => {
                errMessage += e.message + ' \n';
              });
              throw new Error(errMessage);
            } else {
              images.push(resData[0]);
              this.imgLoadProgress = this.imgLoadProgress + step;
            }
          }
        } catch (error) {
          console.log(error.message);
        }
        this.loading = false;
        this.loadingImages = false;
        this.overlay = false;

        // Тут уже имеем загруженные картинки.
        // Добавляем _id вновь загруженных картинок в массив файлов

        const resultFiles = [];

        for (const image of images) {
          resultFiles.push(image._id);
        }

        const token = this.$store.state.token;
        const query = `mutation ConnectionUpdateResultFiles($id: String!, $resultFiles: [String]!) {
          connectionUpdateResultFiles(id: $id, resultFiles: $resultFiles) {
            _id
            resultFiles {
              _id
              fileName
              filePath
              tag
              subTitle
              subType
              createdBy { _id name }
            }
          }
        }`;
        const variables = {
          id: this.doc._id.toString(),
          resultFiles: resultFiles
        };

        this.loading = true;
        const result = await sendRequest(token, query, variables);
        // Сообщить о результате
        let message = 'Изменение сохранено';
        if (result.error) {
          message = `Ошибка: ${result.error}`;
          this.loading = false;
        } else {
          // Получены обновленные данные
          this.$emit(
            'onUpdate',
            result.data.connectionUpdateResultFiles.resultFiles
          );
        }
        this.loading = false;
        this.setMessageData({
          message,
          type: 'snackbar'
        });
      }
    }
  }
};
</script>

<style></style>
