<template>
  <v-card class="mx-auto" tile style="margin: 20px 0" flat>
    <v-card-title>ПАО Красноярскэнергосбыт</v-card-title>
    <v-card-subtitle
    >Документы полученные от ПАО Красноярскэнергосбыт</v-card-subtitle
    >
    <v-card-text>
      <v-list link dense>
        <v-list-item
            v-for="(item, i) in doc.sbitFiles"
            :key="i"
            :href="
            path +
              'images/' +
              item.createdBy._id.toString() +
              '/' +
              item.fileName
          "
            target="_blank"
        >
          <v-list-item-icon>
            <v-icon>mdi-file</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.tag }}</v-list-item-title>
            {{ item.fileName.slice(37) }}
          </v-list-item-content>
          <v-list-item-action> </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { sendRequest } from '../../../../utils/graphql';
import { CONNECTION } from '../../../../utils/mainConsts';

import FilesLoader from '../../../ui/ConnectionFilesLoader';

export default {
  name: 'ProviderFiles',
  props: ['document'],
  data: () => ({
    dialog: false,
    selected: '',
    path: process.env.VUE_APP_REST,
    doc: {},
  }),
  computed: {
    ...mapGetters(['getToken', 'getRole']),
    userRole() {
      return this.$store.state.role;
    }
  },
  mounted() {
    this.doc = this.document;
  },
};
</script>

<style></style>
