<template>
  <v-card flat>
    <v-card-title
      >Приложенные файлы<v-spacer> </v-spacer>
      <v-btn
        class="ma-2"
        :loading="loading"
        :disabled="loading"
        color="primary"
        @click="downloadZipHandler"
      >
        Скачать архивом
        <template v-slot:loader>
          <span class="custom-loader">
            <v-icon light>mdi-cached</v-icon>
          </span>
        </template>
      </v-btn>
    </v-card-title>
    <v-spacer></v-spacer>
    <v-list link dense>
      <v-list-item
        v-for="(item, i) in files"
        :key="i"
        :href="
          path + 'images/' + item.createdBy._id.toString() + '/' + item.fileName
        "
        target="_blank"
      >
        <v-list-item-icon>
          <v-icon>mdi-file</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>({{
              item.createdAt | date('datetime')
            }}) {{ item.fileName.slice(37) }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-icon>mdi-information</v-icon>
              </span>
            </template>
            <span>{{ fileTip[item.tag] }}</span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

const PATH = process.env.VUE_APP_ZIP;

export default {
  name: 'ConnectionFiles',
  props: ['files', 'id'],
  data: () => ({
    path: process.env.VUE_APP_REST,
    loading: false,
    fileTip: {
      plan:
        'План расположения энергопринимающего устройства присоединяемого к сетям сетевой организации',
      energySchema:
        'Однолинейная схема электрических сетей присоединяемых к электрическим сетям сетевой организации, номинальный класс напряжения которых составляет 35 кВ и выше, с указанием возможности резервирования от собственных источников энергоснабжения (включая резервирование для собственных нужд) и возможности переключения нагрузок (генерации) по внутренним сетям заявителя',
      ownership:
        'Копия документа, подтверждающего право собственности или иное предусмотренное законом основание на объект капитального строительства (нежилое помещение в таком объекте капитального строительства) и (или) земельный участок, на котором расположены (будут располагаться) объекты заявителя, либо право собственности или иное предусмотренное законом основание на энергопринимающие устройства (для заявителей, планирующих осуществить технологическое присоединение энергопринимающих устройств потребителей, расположенных в нежилых помещениях многоквартирных домов или иных объектах капитального строительства, - копия документа, подтверждающего право собственности или иное предусмотренное законом основание на нежилое помещение в таком многоквартирном доме или ином объекте капитального строительства)',
      ukAgreement:
        'Копия документа, подтверждающего согласие организации, осуществляющей управление многоквартирным домом, при наличии у такой организации соответствующих полномочий либо при ее отсутствии или отсутствии у нее полномочий согласие общего собрания владельцев жилых помещений многоквартирного дома на организацию присоединения нежилого помещения отдельными линиями от вводного устройства (вводно-распределительного устройства, главного распределительного щита), установленного на вводе питающей линии сетевой организации в соответствующее здание или его обособленную часть (если для соответствующего нежилого помещения проектом на многоквартирный дом не предусмотрено индивидуальное вводно-распределительное устройство с непосредственным присоединением к питающей линии сетевой организации)',
      misc: 'Прочий документ'
    }
  }),
  computed: {
    ...mapGetters(['getToken'])
  },
  mounted() {},
  methods: {
    ...mapMutations(['setMessageData']),
    async downloadZipHandler() {
      console.log(this.files);
      try {
        const res = await fetch(PATH + `?id=${this.id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.getToken
          }
        });
        if (res.status === 200) {
          const blob = await res.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'images.zip';
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); // afterwards we remove the element again
        } else {
          throw new Error('Error');
        }
      } catch (error) {
        this.setMessageData({
          message: 'Ошибка при загрузке файлов!'
        });
      }
    }
  }
};
</script>

<style></style>
