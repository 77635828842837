<template>
  <v-card class="mx-auto" tile style="margin: 20px 0" flat>
    <v-card-title>Документы на основании заявки</v-card-title>
    <v-card-subtitle
      >Документы сформированные сетевой организацией на основании
      заявки</v-card-subtitle
    >
    <v-card-text>
      <v-list link dense>
        <v-list-item
          v-for="(item, i) in doc.resultFiles"
          :key="i"
          :href="
            path +
              'images/' +
              item.createdBy._id.toString() +
              '/' +
              item.fileName
          "
          target="_blank"
        >
          <v-list-item-icon>
            <v-icon>mdi-file</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.tag }}</v-list-item-title>
            {{ item.subType }}
          </v-list-item-content>
          <v-list-item-action> </v-list-item-action>
        </v-list-item>
      </v-list>
      <files-loader :doc="doc" v-if="getRole >= 5" @onUpdate="updateFiles" />
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">
            Удаление файла
          </v-card-title>
          <v-card-text>Уверены что хотите удалить файл?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">
              Отмена
            </v-btn>
            <v-btn
              v-if="userRole >= 5"
              color="green darken-1"
              text
              @click="confirmDelete"
            >
              Удалить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { sendRequest } from '../../../../utils/graphql';
import { CONNECTION } from '../../../../utils/mainConsts';

import FilesLoader from '../../../ui/ConnectionFilesLoader';

export default {
  name: 'DocumentCtrl',
  components: {
    'files-loader': FilesLoader
  },
  props: ['document'],
  data: () => ({
    dialog: false,
    selected: '',
    path: process.env.VUE_APP_REST,
    doc: {},
    fileTip: {
      plan:
        'План расположения энергопринимающего устройства присоединяемого к сетям сетевой организации',
      energySchema:
        'Однолинейная схема электрических сетей присоединяемых к электрическим сетям сетевой организации, номинальный класс напряжения которых составляет 35 кВ и выше, с указанием возможности резервирования от собственных источников энергоснабжения (включая резервирование для собственных нужд) и возможности переключения нагрузок (генерации) по внутренним сетям заявителя',
      ownership:
        'Копия документа, подтверждающего право собственности или иное предусмотренное законом основание на объект капитального строительства (нежилое помещение в таком объекте капитального строительства) и (или) земельный участок, на котором расположены (будут располагаться) объекты заявителя, либо право собственности или иное предусмотренное законом основание на энергопринимающие устройства (для заявителей, планирующих осуществить технологическое присоединение энергопринимающих устройств потребителей, расположенных в нежилых помещениях многоквартирных домов или иных объектах капитального строительства, - копия документа, подтверждающего право собственности или иное предусмотренное законом основание на нежилое помещение в таком многоквартирном доме или ином объекте капитального строительства)',
      ukAgreement:
        'Копия документа, подтверждающего согласие организации, осуществляющей управление многоквартирным домом, при наличии у такой организации соответствующих полномочий либо при ее отсутствии или отсутствии у нее полномочий согласие общего собрания владельцев жилых помещений многоквартирного дома на организацию присоединения нежилого помещения отдельными линиями от вводного устройства (вводно-распределительного устройства, главного распределительного щита), установленного на вводе питающей линии сетевой организации в соответствующее здание или его обособленную часть (если для соответствующего нежилого помещения проектом на многоквартирный дом не предусмотрено индивидуальное вводно-распределительное устройство с непосредственным присоединением к питающей линии сетевой организации)',
      misc: 'Прочий документ'
    }
  }),
  computed: {
    ...mapGetters(['getToken', 'getRole']),
    userRole() {
      return this.$store.state.role;
    }
  },
  mounted() {
    this.doc = this.document;
  },
  methods: {
    ...mapMutations(['setMessageData']),
    updateFiles(files) {
      if (files && Array.isArray(files)) {
        this.doc.resultFiles = { ...files };
      }
    },

    askForDelete(imgId) {
      this.dialog = true;
      this.selected = imgId._id;
    },

    confirmDelete() {
      console.log(this.selected);
      this.dialog = false;
      this.selected = '';
      this.updateFiles();
    }
  }
};
</script>

<style></style>
