<template>
  <v-card class="mx-auto" tile style="margin: 20px 0" flat>
    <v-card-title>Управление заявкой</v-card-title>
    <v-spacer></v-spacer>
    <v-card-text>
      <v-row>
        <v-col> </v-col>
        <v-col>
          <v-text-field
            v-model="doc.enterNum"
            outlined
            dense
            label="Вх. номер"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            :items="status"
            v-model="doc.status"
            label="Статус"
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col>
          <v-text-field
            v-model="doc.bailee.name"
            outlined
            dense
            label="Ответственное лицо"
            disabled
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="onSave">Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { sendRequest } from '../../../../utils/graphql';
import { CONNECTION } from '../../../../utils/mainConsts';

export default {
  name: 'DocumentCtrl',
  props: ['document'],
  data: () => ({
    status: CONNECTION.status,
    doc: {
      enterNum: 0,
      status: 0,
      bailee: {
        name: ''
      }
    }
  }),
  computed: {
    ...mapGetters(['getToken', 'getRole'])
  },
  mounted() {
    this.doc = this.document;
  },
  methods: {
    ...mapMutations(['setMessageData']),
    async onSave() {
      const token = this.getToken;
      const query = `mutation ChangeStatus($id: String!, $status: Int, $bailee: String, $enterNum: String) {
        connectionChangeStatus(id: $id, status: $status, bailee: $bailee, enterNum: $enterNum) {
          _id
        }
      }`;
      const variables = {
        id: this.doc._id.toString(),
        status: this.doc.status,
        bailee: this.$store.state.userId,
        enterNum: this.doc.enterNum
      };

      const result = await sendRequest(token, query, variables);
      // Сообщить о результате
      let message = 'Изменение сохранено';
      if (result.error) {
        message = `Ошибка: ${result.error}`;
      }
      this.setMessageData({
        message,
        type: 'snackbar'
      });
    }
  }
};
</script>

<style></style>
