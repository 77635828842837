<template>
  <v-card class="mx-auto" tile style="margin: 20px 0" flat>
    <v-card-title>Комментарии к заявке</v-card-title>
    <v-spacer></v-spacer>
    <v-list-item link v-for="m in messages" :key="m._id">
      <v-list-item-avatar>
        <v-icon class="green lighten-4" dark> mdi-message </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-subtitle>{{ m.message }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if="role >= 5">
        <v-list-item-action-text>
          {{ m.createdAt }}
        </v-list-item-action-text>
        <v-btn icon>
          <v-icon color="grey lighten-1" @click.prevent="onDelete(m._id)"
            >mdi-delete</v-icon
          >
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-row style="margin: 10px 0" v-if="role >= 5">
      <v-col cols="8">
        <v-text-field
          v-model.trim="messageField"
          label="Новое сообщение"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-btn @click.prevent="onSend">Отправить</v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { sendRequest } from '../../utils/graphql';

export default {
  props: ['messages', 'partner', 'document', 'documentId'],
  data: () => ({
    messageField: ''
  }),
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    role: function() {
      return this.$store.state.role;
    }
  },
  methods: {
    onDelete(id) {
      const token = this.$store.state.token;
      const query = `mutation CommentDelete($id: String) {
        commentRemove(id: $id) {
          _id
        }
      }`;
      const variables = {
        id
      };

      sendRequest(token, query, variables, result => {
        // Тут загружены все комментарии если они были
        if (result.error) {
          console.log('Ошибка при загрузке сообщений');
        } else {
          this.$emit('toUpdate');
        }
      });
    },
    onSend() {
      // Путые строки не отправляем
      this.messageField = this.messageField.trim();
      if (this.messageField.length <= 0) {
        return;
      }

      const token = this.$store.state.token;
      const query = `mutation CommentCreate($itemInput: CommentInput) {
        commentCreate(itemInput: $itemInput) {
          _id
          message
          createdAt
          createdBy { name }
        }
      }`;
      const variables = {
        itemInput: {
          message: this.messageField,
          partner: this.partner,
          document: this.document,
          documentId: this.documentId,
          createdBy: this.userId
        }
      };

      sendRequest(token, query, variables, result => {
        // Тут загружены все комментарии если они были
        if (result.error) {
          console.log('Ошибка при загрузке сообщений');
        } else {
          this.messageField = '';
          this.$emit('toUpdate');
        }
      });
    }
  }
};
</script>

<style scoped>
.messages-field {
  padding: 30px;
  margin: 20px 0;
  width: 100%;
  border-radius: 5px;
  background-color: #ececec;
  position: relative;
}

.messages-field header {
  position: absolute;
  background-color: white;
  font-size: 10px;
  top: -10px;
  left: 15px;
  color: black;
}

.message {
  margin: 10px;
  padding: 10px;
  background-color: cornflowerblue;
  color: black;
  border-radius: 5px;
}
</style>
